import { useFetcher, useLoaderData, useNavigate } from '@remix-run/react';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSetOnboardingFinished } from '~/store/onboarding';
import { cn } from '~/utils/cn';

import type { loader } from './_index';

const TIME_TO_SHOW_CLOSE_BUTTON = 5; // five seconds

export default function CheckoutPage({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { priceId, priceLabel, duration } = useLoaderData<typeof loader>();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const setOnboardingFinished = useSetOnboardingFinished();

  const onClick = React.useCallback(async () => {
    navigate({
      pathname: '/stripe',
      search: `?price_id=${priceId}`,
    });

    setOnboardingFinished();
  }, [priceId, navigate, setOnboardingFinished]);

  const handleClose = React.useCallback(() => {
    onClose();

    fetcher.submit(null, { method: 'POST', action: '/update-session' });
  }, [fetcher, onClose]);

  return (
    <motion.div
      className={cn(
        'relative md:h-[95vh] sm:h-[100vh] h-[100vh]',
        'xl:w-[40vw] lg:w-[50vw] md:w-[60vw] sm:w-[100vw] w-[100vw]'
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <img
        src={'/images/paywall/books.png'}
        alt="paywall"
        className={cn(
          'h-full w-full object-cover rounded-lg pointer-events-none select-none',
          'opacity-30'
        )}
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center gap-8 mx-4">
        <motion.div
          className="absolute top-4 right-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: TIME_TO_SHOW_CLOSE_BUTTON, duration: 0.5 }}
        >
          <CloseButton onClick={handleClose} />
        </motion.div>
        <div className="bg-black rounded-xl p-6 border-[1px] border-white border-opacity-20">
          <img
            src="/images/dygest-logo.svg"
            alt="Dygest logo"
            className={cn('w-[80px] h-[80px]')}
          />
        </div>
        <div className="select-none">
          <div className="text-center mb-4">
            <p
              className={cn(
                'font-sFProText text-left text-white',
                'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
                'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
                'inline'
              )}
            >
              {t('payment.title')}
            </p>{' '}
            <p
              className={cn(
                'font-sFProText font-bold text-left text-white',
                'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
                'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
                'inline'
              )}
            >
              {t('payment.titleBold')}
            </p>{' '}
            <p
              className={cn(
                'font-sFProText text-left text-white',
                'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
                'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
                'inline'
              )}
            >
              {t('payment.titleTwo')}
            </p>{' '}
            <p
              className={cn(
                'font-sFProText font-bold text-left text-white',
                'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
                'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
                'inline'
              )}
            >
              {t('payment.titleBoldTwo')}
            </p>{' '}
            <p
              className={cn(
                'font-sFProText text-left text-white',
                'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
                'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
                'inline'
              )}
            >
              {t('payment.titleThree')}
            </p>
          </div>
        </div>

        <div className="gap-4">
          {[1, 2, 3].map((labelIndex) => (
            <TextGroup key={labelIndex} labelIndex={labelIndex} />
          ))}
        </div>
        <div
          className={cn(
            'flex flex-col gap-1 w-full',
            'md:absolute md:bottom-6'
          )}
        >
          <p className="font-inter text-white opacity-80 text-xs leading-3">
            {t('payment.only') +
              ' ' +
              t(`payment.plan.price.${duration}`, { price: priceLabel })}
          </p>
          <SubscribeButton onClick={onClick} />
        </div>
      </div>
    </motion.div>
  );
}

/* Components */
function CloseButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <button
      className="w-8 h-8 flex items-center justify-center rounded-full bg-black bg-opacity-50"
      onClick={onClick}
    >
      <img src="/images/paywall/close.svg" alt="Close" className="h-3 w-3" />
    </button>
  );
}

function SubscribeButton({ onClick }: { onClick: () => void }): JSX.Element {
  const { t } = useTranslation();

  return (
    <button
      className="px-4 py-2 bg-green bg-opacity-80 hover:bg-opacity-70 rounded w-full"
      onClick={onClick}
    >
      <p className="font-inter font-bold text-white text-lg">
        {t('payment.subscribe')}
      </p>
    </button>
  );
}

function TextGroup({ labelIndex }: { labelIndex: number }): JSX.Element {
  const { t } = useTranslation();

  const labelTextFromIndex = (index: number): string => {
    switch (index) {
      case 1:
        return 'One';
      case 2:
        return 'Two';
      case 3:
        return 'Three';

      default:
        return 'One';
    }
  };

  return (
    <div className="flex flex-row gap-2 mb-4">
      <div className="flex flex-col">
        <img
          src="/images/landing/check.svg"
          alt="Check"
          className={cn(
            'md:text-xl sm:text-lg text-lg',
            'md:leading-6 sm:leading-5 leading-5'
          )}
        />
      </div>

      <div className="flex-1 flex flex-col gap-[2px]">
        <p
          className={cn(
            'font-sFProText font-bold text-left text-white',
            'xl:text-lg lg:text-lg md:text-md sm:text-sm text-sm',
            'xl:leading-5 lg:leading-5 md:leading-4 sm:leading-3 xs:leading-3 leading-3',
            'inline'
          )}
        >
          {t(`payment.description${labelTextFromIndex(labelIndex)}Title`)}
        </p>
        <p
          className={cn(
            'font-sFProText text-left text-white',
            'lg:text-md md:text-sm sm:text-xs text-xs',
            'lg:leading-4 md:leading-3 sm:leading-2 leading-2',
            'inline'
          )}
        >
          {t(`payment.description${labelTextFromIndex(labelIndex)}`)}
        </p>
      </div>
    </div>
  );
}
